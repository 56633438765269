export const environment = {
  production: true,
  pcek: 'ceroyuno',
  app: 1011,
  host: 'https://web-api.liceodefarmacia.com',
  defaultLanguage: 'es',
  stripePublicKey: 'pk_live_POnMS4WqcDh3xFhRwbpVeGao',
  sentryKey: 'https://aac36a7937984612a07e6bb0d3556131@o1184240.ingest.sentry.io/6637325',
  gtmKey: 'GTM-TB5MF5N',
  gKey: 'G-YGK0DB6ET9',
  campusUrl: 'https://campus.liceodefarmacia.com',
};
