import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, skip, Subscription } from 'rxjs';
import { Cart } from 'src/app/cross/cart/domain/Cart';
import { LoggedService } from 'src/app/services/logged.service';
import { StorageService } from 'src/app/services/storage.service';
import { removeLicenseAction } from 'src/app/state/cart.actions';
import { updateCartSelector } from 'src/app/state/cart.selectors';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { AuthGuardService } from 'src/app/services/auth-guard.service';
import { Sponsor } from 'src/app/cross/dashboard/domain/Dashboard';
import { Environments } from 'src/app/utils/Environments';
import { gsap } from 'gsap'
import { ExpoScaleEase } from "gsap/EasePack";
import { Account } from 'src/app/cross/account/domain/Account';
import { AccountLogout } from 'src/app/cross/account/application/AccountLogout';
import { LoggerProviderService } from 'src/app/services/logger/logger-provider.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit, AfterViewInit {

  @ViewChild('desktopNav') desktopNav!: ElementRef;
  @ViewChild('animationWrapper') animationWrapper!: ElementRef;

  @Input() scrolled: boolean = false;

  private readonly store: Store
  public cartOfferNumber: number = 0;
  public license: any[]
  public campusUrl: string = environment.campusUrl;
  private readonly updateCartSelector$: Observable<readonly Cart[]> = new Observable()
  private subcriptionCart: Subscription | undefined

  hamburger: boolean = false
  wrap_menu: boolean = false
  menu_mb: boolean = false

  isVisibleSelectSpaces: boolean = false;

  showCart: boolean = false;
  firstTime: boolean = true;

  pharmacyName: string = '';
  groupName: string = '';
  imgSponsorUrl: string = Environments.getS3UrlEnvironment() + '/assets/webp/sponsors/mobile/';
  assetsEnvironment: string = Environments.getS3UrlEnvironment() + '/assets';

  isLogged: boolean = false;
  hasPlans: boolean = false;
  sponsors: Sponsor[] = [];

  menuOptions: string[] = [
    'Mis suscripciones',
    'Cerrar sesión'
  ]
  userName: string = '';

  constructor(
    store: Store,
    private loggedService: LoggedService,
    public router: Router,
    public googleAnalyticsService: GoogleAnalyticsService,
    private storageService: StorageService,
    private authGuardService: AuthGuardService,
    private accountLogout: AccountLogout,
    private loggerProviderService: LoggerProviderService
  ) {
    this.store = store;
    this.cartOfferNumber = 0;
    this.license = [];
    this.updateCartSelector$ = this.store.select(updateCartSelector);
    gsap.registerPlugin(ExpoScaleEase);
  }

  myProfileText: string = 'Entrar';

  isProfileActiveRoute() {
    return (this.router.url.includes('mi-perfil') || this.router.url.includes('mi-perfil/iniciar-sesion')) ? 'active' : '';
  }
  isClassRoomActiveRoute() {
    return this.router.url.includes('aula') ? 'active' : '';
  }
  isCourseActiveRoute() {
    return (this.router.url.includes('cursos') || this.router.url.includes('cursos')) ? 'active' : '';
  }
  isFreeTrialActiveRoute() {
    return this.router.url.includes('prueba-gratis') ? 'active' : '';
  }
  isDashboardRoute() {
    return this.router.url.includes('aula') ? 'active' : '';
  }
  isCertificateRoute() {
    return this.router.url.includes('certificados') ? 'active' : '';
  }
  isPurchaseOkRoute() {
    return this.router.url.includes('gracias-por-') ? 'active' : '';
  }
  isFreeTrialOrCartActiveRoute() {
    return (this.router.url.includes('prueba-gratis') || this.router.url.includes('compra'));
  }
  isFreeTrialOrPurchaseSelectionRoute(){
    return this.router.url.includes('prueba-gratis/seleccion') || this.router.url.includes('compra/seleccion');
  }
  idAlreadyHaveActivePlanRoute() {
    return this.router.url.includes('ya-tienes-un-plan-activo');
  }
  
  isHideAllRoute(){
    return this.router.url.includes('salud_capilar') || this.router.url.includes('contacto');
  }

  ngOnInit(): void {
    this.setUserData();

    this.showCart = false;
    this.cartOfferNumber = 0
    // if (this.storageService.find(this.storageService.SESSION_TOKEN)) {
    //   this.myProfileText = 'Mi perfil';
    // } else {
    //   this.myProfileText = 'Entrar';
    // }
    // this.loggedService.log_update$.subscribe((logStatus) => {
    //   if (logStatus) {
    //     this.myProfileText = 'Mi perfil';
    //   } else {
    //     this.myProfileText = 'Entrar';
    //   }
    // })
    this.subcriptionCart = this.updateCartSelector$.pipe(skip(1)).subscribe(([cart]) => {

      this.cartOfferNumber = cart?.license?.length
      this.license = cart?.license
      if (this.cartOfferNumber > 0) {
        if (!this.firstTime) {
        } else {
          this.firstTime = false;
        }
      }
    })
    this.closeCart();
    this.authGuardService.isLoginChange().subscribe(res => this.isLogged = res);
    this.authGuardService.hasActivePlanChange().subscribe(res => this.hasPlans = res);
    if (this.isCertificateRoute()) {
      this.authGuardService.updateStatus();
    }
  }

  async setUserData() {
    const userData = await JSON.parse(this.storageService.find(this.storageService.USER_DATA))
    this.userName = userData?.name ?? '';
  }

  ngAfterViewInit(): void {
    this.initAnimations();
    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 0);
  }

  initAnimations() {
    gsap.from(this.desktopNav.nativeElement, {
      opacity: 0,
      duration: .8,
      delay: 0,
    });
  }

  onChangeLogin() {
  }

  ngOnDestroy(): void {
    // If this component destroy remove the subscription
    this.subcriptionCart?.unsubscribe()
  }

  toggleClass() {
    this.hamburger = !this.hamburger;
    this.wrap_menu = !this.wrap_menu;
    this.menu_mb = !this.menu_mb;
  }

  closeMobileMenu() {
    this.hamburger = false;
    this.wrap_menu = false;
    this.menu_mb = false;
  }

  toggleCart() {

    this.showCart = !this.showCart;
  }

  openCart() {
    this.showCart = true;
  }

  closeCart() {
    this.showCart = false;
  }

  goToCart() {
    this.router.navigate(['/carrito']);
    this.closeCart();
  }

  removeLicense(item: any) {
    this.store.dispatch(removeLicenseAction({ licenseId: item.licenseId }));
  }

  onNavMobile(route?: string) {
    if (route) {
      switch (route) {
        case 'stats':
          this.googleAnalyticsService.eventEmitter('click', { area: 'aula', pag: 'navbar', act: route });
          break;
        case 'infiltrada':
          this.googleAnalyticsService.eventEmitter('click', { area: 'aula', pag: 'navbar', act: route });
          break;
        case 'protocolos':
          this.googleAnalyticsService.eventEmitter('click', { area: 'aula', pag: 'navbar', act: route });
          break;
      }
    }
    this.toggleClass();
    this.closeMobileMenu()
  }

  gotoFreeTest(isMobile: boolean) {
    let data = { eventAction: 'click_btn', eventLabel: 'header' };
    this.googleAnalyticsService.eventEmitter("prueba_gratis", data);
    if (isMobile) this.toggleClass();
  }

  onClickDropDownOption(opt: string) {
    switch (opt) {
      case 'Mis suscripciones':
        if(this.idAlreadyHaveActivePlanRoute()){
          // this.router.navigate(['aula/mis-suscripciones'])
        } else {
          this.router.navigate(['mi-perfil'])
        }
        break;
      case 'Cerrar sesión':
        this.onLogOut();
        break;
      default:
        break;
    }
  }

  private async onLogOut() {
    const userData: Account | undefined = await JSON.parse(this.storageService.find(this.storageService.USER_DATA))
    if (userData != null) {
      this.logOut(userData.id, userData.token);
      this.storageService.clearSessionData();
    } else {
      this.router.navigate(['/home']);
    }
  }

  private async logOut(personId: number, token: string) {
    try {
      const result = await this.accountLogout.execute(personId, token)
      if (result) {
        this.storageService.clearSessionData();
        this.loggedService.logOut();
        this.router.navigate(['/home']);
      }
    } catch (err: any) {
      const { message } = err
      this.loggerProviderService.error(err);
    }
  }

  hasSession(): boolean {
    return this.storageService.find(this.storageService.SESSION_TOKEN) ? true : false;
  }

  goToCampus() {
    const a = document.createElement('a');
    a.href = environment.campusUrl;
    a.click();
  }
}
